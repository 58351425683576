<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('student_semester_report')" :isColumns="true"
                        @filter-div-status="datatable.filterStatus = $event"
                        :isNewButton="checkPermission('studentsemester_create')" @new-button-click="showCreateModal">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </Header>
                <HeaderMobile :title="$t('student_semester_report')" :isColumns="true"
                              @filter-div-status="datatable.filterStatus = $event" :isNewButton="true"
                              @new-button-click="showCreateModal">
                    <template v-slot:columns>
                        <div class="mb-1" v-for="(column, key) in datatable.columns" :key="key">
                            <b-form-checkbox :id="'checkbox-' + key" :name="'checkbox-' + key" :value="false"
                                             :unchecked-value="true" v-model="column.hidden"
                                             v-if="column.field != 'buttons'">
                                {{ column.label }}
                            </b-form-checkbox>
                        </div>
                    </template>
                </HeaderMobile>
            </template>
            <datatable-filter v-show="datatable.filterStatus"
                              :export-excel="checkPermission('studentsemester_excelexport')"
                              @exportExcel="exportExcel" @filter="filter" @filterClear="filterClear">
                <b-row>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('name')">
                            <b-form-input type="text" size="sm"
                                          v-model="datatable.queryParams.filter.name"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('surname')">
                            <b-form-input type="text" size="sm"
                                          v-model="datatable.queryParams.filter.surname"></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('student_number')">
                            <b-form-input type="number" size="sm" v-check-min-max-value
                                          v-model="datatable.queryParams.filter.student_number"></b-form-input>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="6" lg="6" xl="6">
                        <b-form-group :label="$t('semester')">
                            <semesters-selectbox
                                :multiple="true"
                                v-model="datatable.queryParams.filter.semester_id"></semesters-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox v-model="datatable.queryParams.filter.faculty_code"></faculty-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('department')">
                            <department-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                                  v-model="datatable.queryParams.filter.department_code"></department-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('program')">
                            <program-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                               :department_code="datatable.queryParams.filter.department_code"
                                               :is_faculty_code_required="true" :is_department_code_required="true"
                                               v-model="datatable.queryParams.filter.program_code"></program-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('registration_type')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.registration_type"
                                                 code="registration_types"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('student_status')">
                            <parameter-selectbox code="student_statuses"
                                                 v-model="datatable.queryParams.filter.student_status"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" xs="12">
                        <b-form-group :label="$t('semester_status')">
                            <parameter-selectbox code="semester_statuses"
                                                 v-model="datatable.queryParams.filter.semester_status"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('major_types')">
                            <parameter-selectbox :faculty_code="datatable.queryParams.filter.faculty_code"
                                                 code="major_types"
                                                 v-model="datatable.queryParams.filter.major_type"></parameter-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('nationality')">
                            <country-selectbox valueType="code" v-model="datatable.queryParams.filter.nationality_code"
                                               :placeholder="$t('nationality')"></country-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('payment_status')">
                            <yes-no-selectbox v-model="datatable.queryParams.filter.payment_status"></yes-no-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('registration_academic_year')">
                            <academic-years-selectbox
                                v-model="datatable.queryParams.filter.registration_academic_year"></academic-years-selectbox>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('registration_semester')">
                            <parameter-selectbox code="semester"
                                                 v-model="datatable.queryParams.filter.registration_semester"></parameter-selectbox>
                        </b-form-group>
                    </b-col>
                    <b-col sm="6" md="3" lg="2" xl="2">
                        <b-form-group :label="$t('class')">
                            <parameter-selectbox v-model="datatable.queryParams.filter.class" code="classes"/>
                        </b-form-group>
                    </b-col>

                    <b-col sm="6" md="5" lg="3" xl="4">
                        <b-form-group :label="$t('leaving_date')">
                            <v-date-picker v-model="datatable.queryParams.filter.leaving_date" is-range>
                                <template v-slot="{ inputValue, inputEvents }">
                                    <div class="d-flex align-items-center">
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 mr-1">
                                                <input :value="inputValue.start" v-on="inputEvents.start"
                                                       :placeholder="$t('start_date')" class="form-control"/>
                                            </b-form-group>
                                        </div>
                                        <div class="flex-grow-1">
                                            <b-form-group class="m-0 ml-1">
                                                <input :value="inputValue.end" v-on="inputEvents.end"
                                                       :placeholder="$t('end_date')" class="form-control"/>
                                            </b-form-group>
                                        </div>
                                    </div>
                                </template>
                            </v-date-picker>
                        </b-form-group>
                    </b-col>
                </b-row>
            </datatable-filter>
            <datatable :isLoading.sync="datatable.isLoading" :columns="datatable.columns" :rows="datatable.rows"
                       :total="datatable.total" :queryParams="datatable.queryParams" @on-page-change="onPageChange"
                       @on-sort-change="onSortChange" @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable">
            </datatable>
            <CommonModal size="md" ref="statusModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('period_status') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="statusForm">
                            <ValidationProvider name="semester_statuses" rules="required" v-slot="{ errors }">
                                <b-form-group :label="''">
                                    <parameter-selectbox v-model="updateForm.semester_status" code="semester_statuses"
                                                         :validateError="errors[0]">
                                    </parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="class" rules="required" v-slot="{ errors }">
                                <b-form-group :label="''">
                                    <parameter-selectbox v-show="classShowStatus" v-model="updateForm.class"
                                                         code="classes"
                                                         :validateError="errors[0]">
                                    </parameter-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="graduate_detail_cgpa" v-slot="{ errors }" rules="">
                                <b-form-group :label="$t('cgpa')">
                                    <b-form-input type="number" step=".01" v-model="updateForm.cgpa"
                                                  :class="errors[0] ? 'is-invalid' : ''" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="graduate_detail_cgpa" v-slot="{ errors }" rules="">
                                <b-form-group :label="$t('Graduation_GPA')">
                                    <b-form-input type="number" step=".01" v-model="updateForm.graduate_detail_cgpa"
                                                  :class="errors[0] ? 'is-invalid' : ''" />
                                    <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider
                                v-if="['112', '121', '122','165'].includes(updateForm.semester_status)"
                                name="university_country_id" rules="required" v-slot="{ errors }">
                                <b-form-group :label="''">
                                    <country-selectbox v-model="updateForm.university_country_id"
                                                       :validateError="errors[0]">
                                    </country-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider
                                v-if="['112', '121', '122','165'].includes(updateForm.semester_status)"
                                name="exchange_university_id" rules="required" v-slot="{ errors }">
                                <b-form-group :label="''">
                                    <university-selectbox :country-id="updateForm.university_country_id"
                                                          v-model="updateForm.exchange_university_id"
                                                          :validateError="errors[0]">
                                    </university-selectbox>
                                </b-form-group>
                            </ValidationProvider>
                        </ValidationObserver>
                        <b-button @click="updateSemesterStatus" variant="primary">{{ $t('save') }}</b-button>
                    </div>
                </template>
            </CommonModal>
            <CommonModal size="md" ref="createModal">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('new') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div>
                        <ValidationObserver ref="createForm">
                            <ValidationProvider name="semester_id" rules="required" v-slot="{ errors }">
                                <b-form-group :label="''">
                                    <semesters-selectbox v-model="semester_id" :validateError="errors[0]">
                                    </semesters-selectbox>
                                </b-form-group>
                                <b-button @click="createSemester" variant="primary">{{ $t('save') }}</b-button>
                            </ValidationProvider>
                        </ValidationObserver>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import StudentSemesterService from "@/services/StudentSemesterService";
import AcademicYearsSelectbox from "@/components/interactive-fields/AcademicYearsSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import ProgramSelectbox from "@/components/interactive-fields/ProgramSelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import YesNoSelectbox from "@/components/interactive-fields/YesNoSelectbox";
import CountrySelectbox from "@/components/interactive-fields/CountrySelectbox";
import CommonModal from "@/components/elements/CommonModal";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import SemestersSelectbox from "@/components/interactive-fields/SemestersSelectbox"
import UniversitySelectbox from "@/components/interactive-fields/UniversitySelectbox"

import {mapGetters} from "vuex"

export default {
    components: {
        ValidationObserver,
        ValidationProvider,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        AcademicYearsSelectbox,
        FacultySelectbox,
        DepartmentSelectbox,
        ProgramSelectbox,
        ParameterSelectbox,
        YesNoSelectbox,
        CountrySelectbox,
        CommonModal,
        SemestersSelectbox,
        UniversitySelectbox
    },
    metaInfo() {
        return {
            title: this.$t("student_semester_report"),
        };
    },
    computed: {
        ...mapGetters(['auth/getActiveRole']),
        classShowStatus() {
            if (["ES", "ESY", "FS", "FSY", "OI"].includes(this['auth/getActiveRole'])) {
                return false
            }
            return true
        }
    },
    data() {
        return {
            updateForm: {
                graduate_detail_cgpa: null,
                cgpa: null
            },
            semesterId: null,
            semester_id: null,
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: '',
                        field: 'buttons',
                        html: true,
                        sortable: false,
                        tdClass: 'p-0 text-center w-40 align-middle',
                        buttons: [
                            {
                                text: this.$t('period_status'),
                                class: 'ri-pencil-fill',
                                permission: "studentsemester_updatestudentsemesterstatus",
                                callback: (row) => {
                                    this.showPeriodStatusModal(row)
                                }
                            },
                            {
                                text: this.$t('delete'),
                                class: 'ri-delete-bin-2-line',
                                permission: "studentsemester_delete",
                                callback: (row) => {
                                    this.deletePeriodStatus(row)
                                }
                            }
                        ]
                    },
                    {
                        label: '#',
                        field: "id",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("id"),
                        field: "id",
                        hidden: true,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("national_id"),
                        field: "national_id",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("student_number"),
                        field: "student_number",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("name"),
                        field: "name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("surname"),
                        field: "surname",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: "academic_year",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("semester"),
                        field: "semester",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("faculty"),
                        field: "faculty_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("department"),
                        field: "department_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("program"),
                        field: "program_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("nationality"),
                        field: "nationality_code",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("registration_type"),
                        field: "registration_type_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("scholarship_rate"),
                        field: "scholarship_rate",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("major_type"),
                        field: "major_type",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("payment_status"),
                        field: "payment_status",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("student_status"),
                        field: "student_status_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("semester_status"),
                        field: "semester_status_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("class"),
                        field: "class_name",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("gpa"),
                        field: "gpa",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("cgpa"),
                        field: "cgpa",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("credits_attempted"),
                        field: "credits_attempted",
                        hidden: false,
                    },
                    {
                        label: this.toUpperCase("ects_credits_attempted"),
                        field: "ects_credits_attempted",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("credits_earned"),
                        field: "credits_earned",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("ects_credits_earned"),
                        field: "ects_credits_earned",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("total_credits_attempted"),
                        field: "total_credits_attempted",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("total_ects_credits_attempted"),
                        field: "total_ects_credits_attempted",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("total_credits_earned"),
                        field: "total_credits_earned",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("total_ects_credits_earned"),
                        field: "total_ects_credits_earned",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("points"),
                        field: "points",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("total_points"),
                        field: "total_points",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("registration_academic_year"),
                        field: "registration_academic_year",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("registration_semester"),
                        field: "registration_semester",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("advisor_id"),
                        field: "advisor",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("leaving_date"),
                        field: "leaving_date",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("medicine_group_code"),
                        field: "medicine_group_code",
                        hidden: false,
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("exchange_university_name"),
                        field: "exchange_university_name",
                        hidden: false,
                        sortable: false,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "-id",
                    page: 1,
                    limit: 20,
                    excel: true,
                },
            },
        };
    },
    created() {
        this.filterSet();
    },
    methods: {
        filterSet() {
            this.datatable.queryParams.filter = {
                id: null,
                student_number: null,
                nationality_code: null,
                academic_year: null,
                faculty_code: null,
                department_code: null,
                program_code: null,
                registration_type: null,
                major_type: null,
                payment_status: null,
                student_status: null,
                semester_id: null,
                semester_status: null,
                registration_semester: null,
                leaving_date: null,
                class:null
            };
        },
        filterClear() {
            this.filterSet();
            this.getRows();
        },
        filter() {
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        onPageChange(params) {
            this.datatable.queryParams.page = params.currentPage;
            this.getRows();
        },
        onPerPageChange(params) {
            this.datatable.queryParams.limit = params.currentPerPage;
            this.datatable.queryParams.page = 1;
            this.getRows();
        },
        changeColumn(key) {
            this.datatable.columns[key].hidden =
                this.datatable.columns[key].hidden == true ? false : true;
        },
        onSortChange(params) {
            const sortType = params[0].type == "desc" ? "-" : "";
            this.datatable.queryParams.sort = sortType + params[0].field;
            this.getRows();
        },
        getRows() {
            this.datatable.showTable = true;
            this.datatable.isLoading = true;

            const config = {
                params: {
                    ...this.datatable.queryParams,
                    sort: "-semester_id"
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            return StudentSemesterService.getAll(config)
                .then((response) => {
                    this.datatable.rows = response.data.data;
                    this.datatable.total = response.data.pagination.total;
                })
                .finally(() => {
                    this.datatable.isLoading = false;
                });
        },
        exportExcel() {
            const config = {
                params: {
                    ...this.datatable.queryParams
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false})
            };
            StudentSemesterService.exportExcel(config)
                .then(res => this._downloadFile(res, this.$t("student_semester_report") + '.xlsx'))
                .catch(err => this.showErrors(err))
        },

        showPeriodStatusModal(row) {
            this.updateForm = row
            this.$refs.statusModal.$refs.commonModal.show()
        },

        async updateSemesterStatus() {
            const isValid = await this.$refs.statusForm.validate();
            if (isValid) {
                const updatedData = {
                    ...this.updateForm,
                    graduate_detail_cgpa: this.updateForm.graduate_detail_cgpa
                        ? parseFloat(parseFloat(this.updateForm.graduate_detail_cgpa).toFixed(2))
                        : null,
                    cgpa: this.updateForm.cgpa
                        ? parseFloat(parseFloat(this.updateForm.cgpa).toFixed(2))
                        : null
                };

                StudentSemesterService.updateStudentSemester(updatedData, this.updateForm.id).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$refs.statusModal.$refs.commonModal.hide()
                    this.updateForm = {};
                }).catch(e => {
                    this.showErrors(e, this.$refs.statusForm)
                })
            }
        },

        deletePeriodStatus(row) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then((result) => {
                if (result.isConfirmed) {
                    StudentSemesterService.del(row.id).then(response => {
                        this.$toast.success(this.$t('api.' + response.data.message))
                        this.getRows()
                    }).catch(e => {
                        this.$toast.error(this.$t('api.' + e.data.message))
                    })
                }
            })
        },

        showCreateModal() {
            this.$refs.createModal.$refs.commonModal.show()
        },

        async createSemester() {
            const isValid = await this.$refs.createForm.validate();
            if (isValid) {
                StudentSemesterService.create(this.semester_id).then(response => {
                    this.$toast.success(this.$t('api.' + response.data.message))
                    this.$refs.createModal.$refs.commonModal.hide()
                    this.$router.push('/job/logs/detail/' + response.data.data.log_id)
                }).catch(e => {
                    this.showErrors(e, this.$refs.createForm)
                })
            }
        }
    }
}
</script>

